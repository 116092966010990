import React from 'react'
import { useParams } from 'react-router-dom'
import MainContentContainer from '../../components/containers/MainContentContainer'
import PerpetratorForm from '../../components/perpetrator/PerpetratorForm'

export default function PerpetratorFormPage () {
  const { id } = useParams()

  return (
    <MainContentContainer
      backButton={ id !== undefined }
      title={ `${id === undefined ? 'Dodavanje' : 'Pregled / Izmjena'} podataka o počiniocu nasilja` }
      content={ <PerpetratorForm /> }
    />
  )
}
