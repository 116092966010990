/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { DetailsList, MessageBar, MessageBarType, SelectionMode, Spinner, Stack } from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import StyledButton from '../styledComponents/StyledButton'
import {
  createSafehouseDataType,
  createAdvisoryDataType,
  deleteSafehouseDataType,
  deleteAdvisoryDataType,
} from '../../assets/axios/services'
import { ServiceCodesStyle } from './ServiceCodes.style'
import StyledTextField from '../styledComponents/StyledTextField'

export default function ServiceCodesList ({ title, data, isAdvisory }: any) {
  const [newType, setNewType] = useState('')
  const [actionInProgress, setActionInProgress] = useState(false)
  const [message, setMessage] = useState({ type: MessageBarType.success, text: '' })
  const [items, setItems] = useState<any>([])
  const [loaded, setLoaded] = useState(false)

  const style = ServiceCodesStyle()

  useEffect(() => {
    setItems(data)
    setTimeout(() => {
      setLoaded(true)
    }, 500)
  }, [data])

  const columns = [
    {
      key: isAdvisory ? 'type' : 'under_type',
      name: 'Tip',
      fieldName: isAdvisory ? 'type' : 'under_type',
      minWidth: 350,
      maxWidth: 350
    },
    // {
    //   key: 'deleteButton',
    //   name: '',
    //   fieldName: 'deleteButton',
    //   minWidth: 80,
    //   maxWidth: 80
    // }

  ]

  const sucessDelete = (item: any) => {
    setItems(items.filter((listItem: any) => listItem.id !== item.id))
    setMessage({ type: MessageBarType.success, text: 'Unos uspiješno obrisan.' })
    setTimeout(() => {
      setMessage({ ...message, text: '' })
    }, 3000)
  }

  const successAdd = (res: any) => {
    setMessage({ type: MessageBarType.success, text: 'Uspiješno kreiran novi tip pomoći.' })
    setActionInProgress(false)
    setItems([res.data, ...items])
    setNewType('')
    setTimeout(() => {
      setMessage({ ...message, text: '' })
    }, 3000)
  }

  const onError = (err: any) => {
    setMessage({ type: MessageBarType.error, text: err.message })
    setActionInProgress(false)
  }

  const onDeleteClick = (item: any) => {
    isAdvisory
      ? deleteAdvisoryDataType(item.id).then(() => {
        sucessDelete(item)
      }).catch((err) => {
        setMessage({ type: MessageBarType.error, text: err.message })
      })
      : deleteSafehouseDataType(item.id).then(() => {
        sucessDelete(item)
      }).catch((err) => {
        setMessage({ type: MessageBarType.error, text: err.message })
      })
  }

  const onAddClick = () => {
    setActionInProgress(true)
    isAdvisory
      ? createAdvisoryDataType({ type: newType }).then((res: any) => {
        successAdd(res)
      }).catch((err) => {
        onError(err)
      })

      : createSafehouseDataType({
        type: title,
        under_type: newType
      }).then((res: any) => {
        successAdd(res)
      }).catch((err) => {
        onError(err)
      })
  }

  const menuProps = (item: any) => ({
    styles: style.menuStyle,
    items: [
      {
        key: 'delete',
        text: 'Potvrdi',
        iconProps: {
          iconName: 'delete',
          styles: style.iconStyle
        },
        onClick: () => { onDeleteClick(item) }
      }, {
        key: 'cancel',
        text: 'Otkaži',
        iconProps: {
          iconName: 'Cancel',
          styles: style.iconStyle,
        }
      }]
  })

  const onRender = (item: any, index: any, column: any) => {
    if (column != null) {
      switch (column?.key) {
        case 'deleteButton':
          return (
            <Stack style={ style.row } horizontalAlign='end'>
              <StyledButton
                height='20px'
                width='40px'
                menuProps={ menuProps(item) }
                text='Obriši'
              />
            </Stack>
          )
        default:
          return (<>{ item[column?.key] }</>)
      }
    }
  }

  return (
    <Stack style={ style.container } tokens={{ childrenGap: 15 }} >
      { message.text.length > 0
        ? <MessageBar
        messageBarType={ message.type }
          onDismiss={ () => { setMessage({ ...message, text: '' }) } }
        >
          { message.text }
        </MessageBar>
        : <div style={{ height: '32px' }}/>
      }
      <Stack horizontal horizontalAlign='space-between' verticalAlign='center'>
        <StyledTextField
          value={ newType }
          onChange={ (e: any, value: any) => { setNewType(value) } }
          placeholder='Unesite novi tip pomoći...'
        />
        <StyledButton
          disabled={ newType.length === 0 || actionInProgress }
          onClick={ () => { onAddClick() } }
         >
          { actionInProgress ? <Spinner /> : 'Dodaj' }
        </StyledButton>
      </Stack>

      <DetailsList
        onRenderItemColumn={ onRender }
        selectionMode={ SelectionMode.none }
        columns={ columns }
        items={ items } />
      { (items.length === 0 && loaded) &&
        <Stack horizontal horizontalAlign='center'>
          nema tipova za ovu vrstu pomoći
        </Stack> }
    </Stack>
  )
}
