/* eslint-disable */
import { DatePicker } from "@fluentui/react";
import moment from "moment";
import React from "react";
import { StyledComponentsStyle } from "./StyledComponents.style";

export default function StyledDatePicker({ ...rest }) {
  const style = StyledComponentsStyle();
  return (
    <DatePicker
      {...rest}
      styles={style.datePicker}
      placeholder="Odaberite..."
      formatDate={(date: moment.MomentInput) =>
        moment(date).format("DD.MM.YYYY")
      }
    />
  );
}
