/* eslint-disable no-console */
import React, { useState } from 'react'
import { Icon, Stack } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'
import { ServiceTypeStyles } from './ServiceTypes.style'

// eslint-disable-next-line react/prop-types
export default function NavigationCard ({ imageSrc, label, link }: any) {
  const navigate = useNavigate()
  const style = ServiceTypeStyles()

  const [hover, setHover] = useState(false)
  const [clicked, setClicked] = useState(false)
  return (
    <Stack
      onClick={ () => { navigate(link) } }
      onMouseEnter={ () => { setHover(true) } }
      onMouseLeave={ () => {
        setHover(false)
        setClicked(false)
      } }
      onMouseDown={ () => { setClicked(true) } }
      onMouseUp={ () => { setClicked(false) } }
      style={{ ...ServiceTypeStyles(hover, clicked).cardContainer, backgroundImage: `url(${imageSrc})` }}
      verticalAlign='end'
      horizontalAlign='start'>
      <span style={ style.upperLabel }>
        { label.split(' ')[0] }
      </span>
      <Stack horizontal horizontalAlign='space-between' style={ style.lowerStack } verticalAlign='center'>
        <span style={ style.lowerLabel }>
          { label.split(' ').splice(1).join(' ') }
        </span>
        <Icon iconName='AddTo' style={ style.icon } />
      </Stack>
    </Stack>
  )
}
