import React from 'react'
import MainContentContainer from '../../components/containers/MainContentContainer'
import UserList from '../../components/User/UserList'

export default function UserListView () {
  return (
    <MainContentContainer
        title={ 'Lista korisnika' }
        content={ <UserList/> }
        />

  )
}
