import { Dropdown } from '@fluentui/react'
import React from 'react'
import { StyledComponentsStyle } from './StyledComponents.style'

export default function StyledDropdown ({ options, ...rest }: any) {
  const style = StyledComponentsStyle()

  const generateTooltips = () => {
    return options.map((item: any) => {
      return { ...item, title: item.text }
    })
  }

  return <Dropdown
  placeholder='Odaberite...'
  styles={ style.dropdown }
  options={ generateTooltips() } { ...rest }/>
}
