/* eslint-disable */
import axios from "axios";
import { getConfig } from "../../config";

// Function to create an Axios instance
const createAxiosInstance = async () => {
  const apiUrl = await getConfig("API_URL");

  if (!apiUrl) {
    throw new Error("API URL not found in config");
  }

  const instance = axios.create({
    baseURL: apiUrl,
    responseType: "json",
  });

  // Add a request interceptor
  instance.interceptors.request.use(
    (config) => {
      const TOKEN = JSON.parse(sessionStorage.getItem("token") ?? "{}");
      if (TOKEN) {
        config.headers!.Authorization = `Bearer ${TOKEN}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
  );

  return instance;
};

// Export a function that provides the axios instance
export const getAxiosInstance = async () => {
  try {
    return await createAxiosInstance();
  } catch (error) {
    console.error("Error creating Axios instance:", error);
    throw error;
  }
};
