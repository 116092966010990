/* eslint-disable */
import React from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import ServicesTypesPage from "./pages/ServiceTypesPage";
import SafeHouseListView from "./pages/safeHouse/SafeHouseListView";
import SafeHouseFormPage from "./pages/safeHouse/SafeHouseFormPage";
import LoginPage from "./pages/LoginPage";
import SosCallFormPage from "./pages/sosCall/SosCallFormPage";
import SosCallListView from "./pages/sosCall/SosCallListView";
import AdvisoryHelpListView from "./pages/advisoryHelp/AdvisoryHelpListView";
import AdvisoryHelpFormPage from "./pages/advisoryHelp/AdvisoryHelpFormPage";
import TrustPersonFormPage from "./pages/trustPerson/TrustPersonFormPage";
import TrustPersonListView from "./pages/trustPerson/TrustPersonListView";
import VictimFormPage from "./pages/victim/VictimFormPage";
import VictimListView from "./pages/victim/VictimListView";
import PerpetratorListView from "./pages/perpetrator/PerpetratorListView";
import PerpetratorFormPage from "./pages/perpetrator/PerpetratorFormPage";
import UserFormPage from "./pages/user/UserFormPage";
import ServiceCodesPage from "./pages/ServiceCodesPage";
// import LockPage from './pages/LockPage'
import UserListView from "./pages/user/UserListView";
import SettingsPage from "./pages/SettingsFormPage";
import SettingsViewPage from "./pages/SettingsViewPage";
import { initializeIcons, PartialTheme, ThemeProvider } from "@fluentui/react";
import { ProtectedRoute } from "./components/protectedRoute/ProtectedRoute";
import MainContentContainer from "./components/containers/MainContentContainer";
import ReportsPage from "./pages/reports/reports";

function App() {
  initializeIcons();

  const myTheme: PartialTheme = {
    palette: {
      themePrimary: "#712776",
      themeDark: "#6B1273",
    },
  };

  return (
    <div className="App">
      <ThemeProvider theme={myTheme}>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/services"
            element={<ProtectedRoute component={<ServicesTypesPage />} />}
          />
          <Route
            path="/services/safehouse"
            element={<ProtectedRoute component={<SafeHouseListView />} />}
          />

          <Route
            path="/services/safehouse/new"
            element={<ProtectedRoute component={<SafeHouseFormPage />} />}
          />
          <Route
            path="/services/safehouse/:id"
            element={<ProtectedRoute component={<SafeHouseFormPage />} />}
          />
          <Route
            path="/services/soscall"
            element={<ProtectedRoute component={<SosCallListView />} />}
          />
          <Route
            path="/services/soscall/new"
            element={<ProtectedRoute component={<SosCallFormPage />} />}
          />
          <Route
            path="/services/soscall/:id"
            element={<ProtectedRoute component={<SosCallFormPage />} />}
          />
          <Route
            path="/services/advisoryhelp"
            element={<ProtectedRoute component={<AdvisoryHelpListView />} />}
          />
          <Route
            path="/services/advisoryhelp/new"
            element={<ProtectedRoute component={<AdvisoryHelpFormPage />} />}
          />
          <Route
            path="/services/advisoryhelp/:id"
            element={<ProtectedRoute component={<AdvisoryHelpFormPage />} />}
          />
          <Route
            path="/services/trustperson"
            element={<ProtectedRoute component={<TrustPersonListView />} />}
          />
          <Route
            path="/services/trustperson/new"
            element={<ProtectedRoute component={<TrustPersonFormPage />} />}
          />
          <Route
            path="/services/trustperson/:id"
            element={<ProtectedRoute component={<TrustPersonFormPage />} />}
          />
          <Route
            path="/victim"
            element={<ProtectedRoute component={<VictimListView />} />}
          />
          <Route
            path="/victim/new"
            element={<ProtectedRoute component={<VictimFormPage />} />}
          />
          <Route
            path="/victim/:id"
            element={<ProtectedRoute component={<VictimFormPage />} />}
          />
          <Route
            path="/perpetrator"
            element={<ProtectedRoute component={<PerpetratorListView />} />}
          />
          <Route
            path="/perpetrator/new"
            element={<ProtectedRoute component={<PerpetratorFormPage />} />}
          />
          <Route
            path="/perpetrator/:id"
            element={<ProtectedRoute component={<PerpetratorFormPage />} />}
          />
          <Route
            path="/user"
            element={<ProtectedRoute component={<UserListView />} />}
          />
          <Route
            path="/user/new"
            element={<ProtectedRoute component={<UserFormPage />} />}
          />
          <Route
            path="/bookcodes"
            element={<ProtectedRoute component={<ServiceCodesPage />} />}
          />
          {/* <Route
          path='/lock'
          element={ <LockPage /> }
        /> */}
          <Route
            path="/settings"
            element={<ProtectedRoute component={<SettingsPage />} />}
          />
          <Route
            path="/about"
            element={<ProtectedRoute component={<SettingsViewPage />} />}
          />
          <Route
            path="/reports"
            element={<ProtectedRoute component={<ReportsPage />} />}
          />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
