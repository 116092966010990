import { COLORS } from '../../assets/theme/colors'

export const VictimStyle = () => ({
  label: {
    width: '100%',
    paddingLeft: '5px',
    textAlign: 'left' as const,
    color: COLORS.darkerGray,
    borderBottom: `1px solid ${COLORS.primary}`
  },
  row: {
    width: '100%'
  },
  topRow: {
    width: '100%',
    paddingRight: '8px'
  },
  messageBar: {
    root: {
      width: '100%',
      maxWidth: '940px'
    }
  },

  pivotContainer: {
    minHeight: '330px'
  },

  tablesHeader: {
    width: '100%',
    textAlign: 'left' as const,
    borderBottom: `2px solid ${COLORS.primary}`,
    padding: '5px',
    paddingBottom: '10px',
    marginBottom: '10px',
    fontSize: '18px',
    color: COLORS.darkerGray,
    fontWeight: '600'
  }
})
