import { Pivot, PivotItem, Stack } from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import { getAdvisoryDataTypes, getSafehouseDataTypes } from '../../assets/axios/services'
import ServiceCodesList from './ServiceCodesList'

export default function ServiceCodesForm () {
  const [safehouseData, setsafehouseData] = useState([])
  const [advisoryData, setAdvisoryData] = useState([])

  useEffect(() => {
    getSafehouseDataTypes().then((res) => {
      setsafehouseData(res.data.items)
    })
    getAdvisoryDataTypes().then((res) => {
      setAdvisoryData(res.data.items)
    })
  }, [])

  const filterByType = (type: string) => {
    return safehouseData.filter((item: any) => item.type === type)
  }

  return (
    <Stack tokens={{ childrenGap: 30 }}>
      <Pivot style={{ textAlign: 'start' }}>
        <PivotItem headerText='Psihološka pomoć'>
          <ServiceCodesList title='Psihološka pomoć' data={ filterByType('Psihološka pomoć') }/>
        </PivotItem>
        <PivotItem headerText='Socijalna pomoć'>
          <ServiceCodesList title='Socijalna pomoć' data={ filterByType('Socijalna pomoć') }/>
        </PivotItem>
        <PivotItem headerText='Pravna pomoć'>
          <ServiceCodesList title='Pravna pomoć' data={ filterByType('Pravna pomoć') }/>
        </PivotItem>
        <PivotItem headerText='Zdravstvena pomoć'>
          <ServiceCodesList title='Zdravstvena pomoć' data={ filterByType('Zdravstvena pomoć') }/>
        </PivotItem>
        <PivotItem headerText='Plan reintegracije'>
          <ServiceCodesList title='Plan reintegracije' data={ filterByType('Plan reintegracije') }/>
        </PivotItem>
        <PivotItem headerText='Savjetodavna pomoć'>
          <ServiceCodesList title='Savjetodavna pomoć' isAdvisory data={ advisoryData }/>
        </PivotItem>
      </Pivot>

    </Stack>
  )
}
