import { COLORS } from '../../assets/theme/colors'

export const AdvisoryHelpStyle = () => ({
  label: {
    width: '100%',
    paddingLeft: '5px',
    textAlign: 'left' as const,
    color: COLORS.darkerGray
  },
  labeledText: {
    height: '32px',
    width: '300px',
    textAlign: 'left' as const,
    paddingLeft: '10px',
    lineHeight: '32px'
  },
  row: {
    width: '100%'
  },
  topRow: {
    width: '100%',
    paddingRight: '8px'
  },
  dropdown: {
    root: {
      width: '300px'
    },
    title: {
      textAlign: 'left'
    },
    label: {
      width: '100%',
      color: COLORS.darkerGray,
      textAlign: 'left' as const,
      paddingLeft: '5px'
    }
  },
  messageBar: {
    root: {
      width: '100%',
      maxWidth: '940px'
    }
  }
})
