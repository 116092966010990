import { COLORS } from '../assets/theme/colors'

export const PagesStyle = () => ({
  container: {
    backgroundColor: COLORS.lightGray,
    width: '100vw',
    height: '100vh'
  },
  formContainer: {
    width: '500px',
    height: '500px',
    backgroundColor: 'white'
  },
  labelContainer: {
    width: '400px',
    paddingBottom: '20px'
  },
  labelLeft: {
    fontWeight: 'bold',
    color: COLORS.darkerGray,
    borderLeft: `5px solid ${COLORS.primary}`,
    fontSize: '24px',
    paddingLeft: '10px'
  },
  labelRight: {
    fontSize: '24px',
    color: COLORS.primary,
    fontWeight: 'bold'
  },
  messageBar: {
    root: {
      width: '300px'
    }
  },
  messageBarPlaceholder: {
    width: '32px'
  }
})
