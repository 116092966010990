import React from 'react'
import { useParams } from 'react-router-dom'
import MainContentContainer from '../../components/containers/MainContentContainer'
import VictimForm from '../../components/victim/VictimForm'

export default function VictimFormPage () {
  const { id } = useParams()

  return (
    <MainContentContainer
        backButton={ id !== undefined }
        title={ `${id === undefined ? 'Dodavanje' : 'Pregled / Izmjena'} podataka o žrtvi nasilja` }
        content={ <VictimForm/> }
        />
  )
}
