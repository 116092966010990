/* eslint-disable */
let configCache = null;

export const getConfig = async (key) => {
  if (configCache === null) {
    try {
      const response = await fetch("/config.json");
      configCache = await response.json();
    } catch (error) {
      console.error("Error loading config:", error);
      return null;
    }
  }
  return configCache[key];
};
