import React from 'react'
import MainContentContainer from '../../components/containers/MainContentContainer'
import PerpetratorList from '../../components/perpetrator/PerpetratorList'

export default function PerpetratorListView () {
  return (
    <MainContentContainer
        title={ 'Lista počinioca nasilja' }
        content={ <PerpetratorList/> }
        />

  )
}
