/* eslint-disable*/
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable brace-style */
/* eslint-disable comma-dangle */
import React, { useEffect, useState } from "react";
import {
  DetailsList,
  IconButton,
  Label,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  SelectionMode,
  Spinner,
  Stack,
  Toggle,
} from "@fluentui/react";
import StyledButton from "../styledComponents/StyledButton";
import { useNavigate, useParams } from "react-router-dom";
import {
  createVictim,
  editVictim,
  getSafehouseEntryByVictim,
  getVictim,
  getSoscallEntriesByVictim,
  getAdvisoryServicesByVictim,
  getTrustedPersonByVictim,
  uploadFile,
  getFilesByVictim,
  downloadFile,
  deleteVictim,
} from "../../assets/axios/services";
import StyledDatePicker from "../styledComponents/StyledDatePicker";
import moment from "moment";
import { DropdownOptions } from "../../assets/consts/DropdownOptions";
import StyledTextField from "../styledComponents/StyledTextField";
import StyledDropdown from "../styledComponents/StyledDropdown";
import { VictimStyle } from "./Victim.style";
import { Columns } from "../../assets/consts/Columns";
import PageNavigation from "../styledComponents/PageNavigation";
import Dropzone from "react-dropzone";
import StyledCommentField from "../styledComponents/StyledCommentField";
import SearchableDropdown from "../styledComponents/SearchableDropdown";
import DropdownWithOptionalField from "../styledComponents/DropdownWithOptionalField";

export default function VictimForm() {
  const [formData, setFormData] = useState<any>(null);
  const [message, setMessage] = useState({
    type: MessageBarType.success,
    text: "",
  });
  const [actionInProgress, setActionInProgress] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [sosCallData, setSosCallData] = useState([]);
  const [advisoryHelpData, setAdvisoryHelpData] = useState([]);
  const [safehouseData, setSafehouseData] = useState([]);
  const [trustPersonData, setTrustPersonData] = useState([]);
  const [filesList, setFilesList] = useState<any>([]);
  const [pagination, setPagination] = useState({});
  const [filteredData, setFilteredData] = useState<any>([]);
  const [fileMessage, setFileMessage] = useState({
    type: MessageBarType.success,
    text: "",
  });
  const [childData, setChildData] = useState<any>([]);
  const [isBihCitizen, setIsBihCitizen] = useState(true);
  const style = VictimStyle();
  const navigate = useNavigate();

  const { id } = useParams();

  const onPageChange = (page: any) => {
    setActionInProgress(true);
    getFilesByVictim(id, page).then((res) => {
      setFilesList(res.data.items ?? []);
      setFilteredData(res.data.items ?? []);
      setPagination({
        total: res.data.total,
        page: res.data.page,
        size: res.data.size,
      });
      setActionInProgress(false);
    });
  };

  const handleMultiSelect = (field: any, value: any) => {
    let temp = [];
    if (formData?.[field] !== null && formData?.[field] !== undefined) {
      temp = formData[field];
    }

    if (value.selected) {
      onInput(field, [...temp, value.key]);
    } else {
      onInput(
        field,
        temp.filter((item: any) => item !== value.key)
      );
    }
  };

  useEffect(() => {
    if (id !== undefined) {
      setIsEdit(true);
      setIsEditable(false);
      getVictim(id).then((res) => {
        setFormData(res.data);
        if (res.data.children) {
          const temp: any = [];
          res.data.children.forEach((childId: any) => {
            getVictim(childId).then((childRes) => {
              temp.push({
                name: `${childRes.data.first_name} ${childRes.data.last_name}`,
                id: childId,
              });
            });
          });
          setChildData(temp);
        }
      });
      getSafehouseEntryByVictim(id).then((res) => {
        const { is_active: isActive } = res.data[0];
        if (!isActive) return;
        setSafehouseData(res.data ?? []);
      });
      getSoscallEntriesByVictim(id).then((res) => {
        setSosCallData(res.data ?? []);
      });
      getAdvisoryServicesByVictim(id).then((res) => {
        setAdvisoryHelpData(res.data ?? []);
      });
      getTrustedPersonByVictim(id).then((res) => {
        setTrustPersonData(res.data ?? []);
      });
      onPageChange(1);
    } else {
      setIsEdit(false);
      setIsEditable(true);
      setFormData(null);
    }
  }, [id]);

  const onSearch = (searchTerm: any) => {
    if (searchTerm.length > 2) {
      setFilteredData(
        filesList.filter((item: any) =>
          item.filename.toLowerCase().match(searchTerm.toLowerCase())
        )
      );
    } else if (searchTerm === "") {
      setFilteredData(filesList);
    }
  };

  const onInput = (fieldName: string, value: any) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const onChildSelection = (value: any, forDeletion?: boolean) => {
    let temp: any = [];
    if (formData?.children !== undefined && formData?.children !== null) {
      temp = formData.children;
    }

    if (forDeletion) {
      onInput(
        "children",
        formData?.children.filter((item: any) => item !== value.id)
      );
      setChildData(childData.filter((item: any) => item.id !== value.id));
    } else if (
      childData.filter((item: any) => item.id === value.key).length === 0
    ) {
      onInput("children", [...temp, value.key]);
      setChildData([...childData, { name: value.text, id: value.key }]);
    }
  };

  const canSubmit = () => {
    return (
      formData?.first_name === undefined ||
      formData?.first_name.length === 0 ||
      formData?.last_name === undefined ||
      formData?.last_name.length === 0 ||
      actionInProgress
    );
  };

  const onSubmit = () => {
    setActionInProgress(true);
    if (isEdit) {
      editVictim(id, formData)
        .then(() => {
          setMessage({
            type: MessageBarType.success,
            text: "Unos uspiješno izmijenjen.",
          });
          setTimeout(() => {
            setIsEditable(false);
            setActionInProgress(false);
            setTimeout(() => {
              setMessage({ type: MessageBarType.success, text: "" });
            }, 1500);
          }, 1500);
        })
        .catch((err) => {
          setActionInProgress(false);
          setMessage({ type: MessageBarType.error, text: err.message });
        });
    } else {
      createVictim(formData)
        .then(() => {
          setMessage({
            type: MessageBarType.success,
            text: "Unos uspiješno kreiran.",
          });
          setTimeout(() => {
            navigate("/victim");
          }, 1500);
        })
        .catch((err) => {
          setActionInProgress(false);
          setMessage({ type: MessageBarType.error, text: err.message });
        });
    }
  };

  const onRender = (item: any, index: any, column: any, navigateTo: any) => {
    if (column != null) {
      switch (column?.key) {
        case "moreButton":
          return (
            <Stack
              style={{ ...style.row, height: "100%" }}
              horizontalAlign="end"
              verticalAlign="center"
            >
              <StyledButton
                height="20px"
                onClick={() => {
                  navigate(`${navigateTo}${item.id}`);
                }}
                text="Pregledaj"
              />
            </Stack>
          );

        case "victim":
          return (
            <Stack style={{ height: "100%" }} verticalAlign="center">
              {`${item.victim_first_name} ${item.victim_last_name}`}
            </Stack>
          );

        case "perpetrator":
          return (
            <Stack style={{ height: "100%" }} verticalAlign="center">
              {`${item.perpetrator_first_name ?? ""} ${
                item.perpetrator_last_name ?? ""
              }`}
            </Stack>
          );

        case "download":
          return (
            <Stack style={style.row} horizontalAlign="end">
              <StyledButton
                height="20px"
                text="Preuzmi"
                onClick={() => {
                  setFileMessage({
                    type: MessageBarType.info,
                    text: "Preuzimanje u toku...",
                  });
                  downloadFile(item.id)
                    .then((res) => {
                      const url = `data:${item.file_type};base64,${res.data}`;
                      fetch(url).then(async (res) => await res.blob());
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", item.filename);
                      document.body.appendChild(link);
                      link.click();
                      setTimeout(() => {
                        setFileMessage({
                          type: MessageBarType.success,
                          text: "Preuzimanje uspiješno završeno.",
                        });
                        setTimeout(() => {
                          setFileMessage({
                            type: MessageBarType.info,
                            text: "",
                          });
                        }, 1500);
                      }, 1500);
                    })
                    .catch((err) => {
                      setFileMessage({
                        type: MessageBarType.error,
                        text: err.message,
                      });
                    });
                }}
              />
            </Stack>
          );

        case "created_date":
          return (
            <Stack style={{ height: "100%" }} verticalAlign="center">
              {moment(item[column?.key]).format("DD.MM.YYYY")}
            </Stack>
          );

        case "delete":
          return (
            <Stack
              style={{ height: "100%", width: "100%" }}
              verticalAlign="center"
              horizontalAlign="center"
            >
              <IconButton
                iconProps={{ iconName: "delete" }}
                onClick={() => onChildSelection(item, true)}
              />
            </Stack>
          );

        default:
          return (
            <Stack style={{ height: "100%" }} verticalAlign="center">
              {item[column?.key]}
            </Stack>
          );
      }
    }
  };

  return (
    <Stack tokens={{ childrenGap: 50 }}>
      <Stack tokens={{ childrenGap: 15 }}>
        {message.text.length > 0 ? (
          <MessageBar
            onDismiss={() => {
              setMessage({ ...message, text: "" });
            }}
            messageBarType={message.type}
            styles={style.messageBar}
          >
            {message.text}
          </MessageBar>
        ) : (
          <div style={{ height: "32px" }} />
        )}

        <Pivot style={{ textAlign: "left" }}>
          <PivotItem headerText="Osnovni podaci" style={style.pivotContainer}>
            <Toggle
              label="Da li je žrtva državljanin BiH?"
              onText="Da"
              offText="Ne"
              checked={isBihCitizen}
              onChange={() => setIsBihCitizen(!isBihCitizen)}
            />
            <Stack
              horizontal
              style={{ maxWidth: "1000px" }}
              tokens={{ childrenGap: 20 }}
              wrap
              enableScopedSelectors
            >
              <StyledTextField
                disabled={!isEditable}
                required
                label="Ime"
                name="first_name"
                value={formData?.first_name}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
              />
              <StyledTextField
                disabled={!isEditable}
                required
                label="Prezime"
                name="last_name"
                value={formData?.last_name}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
              />
              <StyledTextField
                disabled={!isEditable}
                label="Ime roditelja"
                name="parent_name"
                value={formData?.parent_name}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
              />
              <StyledTextField
                disabled={!isEditable}
                label="Datum rođenja"
                name="birth_date"
                value={formData?.birth_date}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
              />
              <StyledDropdown
                disabled={!isEditable}
                label="Dob korisnika/ce"
                options={DropdownOptions.ageOptions}
                selectedKey={formData?.age}
                onChange={(e: any, option: any) => {
                  onInput("age", option.key);
                }}
              />
              <StyledTextField
                disabled={!isEditable}
                label="Mjesto rođenja"
                name="birth_place"
                value={formData?.birth_place}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
              />
              <StyledDropdown
                options={DropdownOptions.genderOptions}
                label="Pol"
                disabled={!isEditable}
                selectedKey={formData?.gender}
                onChange={(e: any, option: any) => {
                  onInput("gender", option.key);
                }}
              />

              {isBihCitizen ? (
                <StyledTextField
                  disabled={!isEditable}
                  label="JMB"
                  name="jmb"
                  value={formData?.jmb}
                  onChange={(e: any, value: any) => {
                    onInput(e.target.name, value);
                  }}
                />
              ) : (
                <StyledTextField
                  disabled={!isEditable}
                  label="Broj pasoša"
                  name="passportId"
                  value={formData?.passportId}
                  onChange={(e: any, value: any) => {
                    onInput(e.target.name, value);
                  }}
                />
              )}
              <StyledTextField
                disabled={!isEditable}
                label="Broj lične karte"
                name="identity_card"
                value={formData?.identity_card}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
              />
              <StyledTextField
                disabled={!isEditable}
                label="Adresa"
                name="address"
                value={formData?.address}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
              />

              <StyledTextField
                disabled={!isEditable}
                label="Državljanstvo"
                name="citizenship"
                value={formData?.citizenship}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
              />

              <StyledDropdown
                disabled={!isEditable}
                options={DropdownOptions.victimType}
                label="Tip žrtve"
                selectedKey={formData?.victim_type}
                onChange={(e: any, option: any) => {
                  onInput("victim_type", option.key);
                }}
              />
            </Stack>
          </PivotItem>
          <PivotItem headerText="Lični podaci" style={style.pivotContainer}>
            <Stack
              horizontal
              style={{ maxWidth: "1000px" }}
              verticalAlign="baseline"
              tokens={{ childrenGap: 20 }}
              wrap
              enableScopedSelectors
            >
              <DropdownWithOptionalField
                disabled={!isEditable}
                options={DropdownOptions.workStatusOptions}
                label="Radni status"
                selectedKey={formData?.working_status}
                showTextFieldOption="other"
                onChange={(e: any, value: any) => {
                  onInput("working_status", value.key ?? value);
                }}
              />
              <DropdownWithOptionalField
                disabled={!isEditable}
                options={DropdownOptions.perpetratorRelationOptions}
                label="Relacija sa počiniocem nasilja"
                selectedKey={formData?.relationship_violence_perpetrator}
                showTextFieldOption="other"
                onChange={(e: any, value: any) => {
                  onInput(
                    "relationship_violence_perpetrator",
                    value.key ?? value
                  );
                }}
              />
              <DropdownWithOptionalField
                disabled={!isEditable}
                options={DropdownOptions.trueFalseOption}
                label="Osoba sa invaliditetom"
                selectedKey={formData?.disability}
                showTextFieldOption="yes"
                onChange={(e: any, value: any) => {
                  onInput("disability", value.key ?? value);
                }}
              />
              <StyledDropdown
                disabled={!isEditable}
                options={DropdownOptions.marriageOptions}
                label="Bračni status"
                selectedKey={formData?.marriage_status}
                onChange={(_e: any, option: any) => {
                  onInput("marriage_status", option.key);
                }}
              />
              <StyledDropdown
                disabled={!isEditable}
                options={DropdownOptions.trueFalseOption}
                label="Imate li djecu"
                selectedKey={formData?.have_children}
                onChange={(e: any, option: any) => {
                  onInput("have_children", option.key);
                }}
              />
              <StyledDropdown
                disabled={!isEditable}
                options={DropdownOptions.trueFalseOption}
                selectedKey={formData?.children_in_safehouse}
                label="Da li su djeca smještena u sigurnu kuću"
                onChange={(e: any, option: any) => {
                  onInput("children_in_safehouse", option.key);
                }}
              />
              <StyledDropdown
                disabled={!isEditable}
                options={DropdownOptions.trueFalseOption}
                label="Da li dijete ima posledice nasilja"
                selectedKey={formData?.children_violent_consequence}
                onChange={(e: any, option: any) => {
                  onInput("children_violent_consequence", option.key);
                }}
              />
              <StyledDropdown
                disabled={!isEditable}
                options={DropdownOptions.methodOptions}
                selectedKeys={formData?.method_victim_ac ?? []}
                label="Način smještaja žrtve u sigurnu kuću"
                multiSelect
                onChange={(e: any, option: any) => {
                  handleMultiSelect("method_victim_ac", option);
                }}
              />
              {/* <StyledDropdown
                disabled={ !isEditable }
                label='Odluka o napuštanju sigurne kuće'
                options={ DropdownOptions.decisionByOptions }
                selectedKey={ formData?.leave_decision }
                onChange={ (e: any, option: any) => { onInput('leave_decision', option.key) } }
              /> */}
              <StyledDropdown
                disabled={!isEditable}
                options={DropdownOptions.decisionByOptions}
                label="Odluka o napuštanju sigurne kuće"
                selectedKey={formData?.leave_decision}
                onChange={(e: any, option: any) => {
                  onInput("leave_decision", option.key);
                }}
              />
              <StyledCommentField
                multiline
                rows={4}
                name="previous_experience"
                disabled={!isEditable}
                label="Prethodno radno iskustvo"
                value={formData?.previous_experience}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
              />
              <DropdownWithOptionalField
                disabled={!isEditable}
                label="Status žrtve nakon napuštanja sigurne kuće"
                options={DropdownOptions.statusOptions}
                selectedKey={formData?.after_leaving_status}
                showTextFieldOption="other"
                onChange={(e: any, option: any) => {
                  onInput("after_leaving_status", option.key ?? option);
                }}
              />
            </Stack>
          </PivotItem>
          <PivotItem
            headerText="Sociodemografski podaci"
            style={style.pivotContainer}
          >
            <Stack
              horizontal
              style={{ maxWidth: "1000px" }}
              tokens={{ childrenGap: 20 }}
              wrap
              enableScopedSelectors
            >
              <StyledDropdown
                disabled={!isEditable}
                options={DropdownOptions.educationOptions}
                label="Nivo obrazovanja"
                selectedKey={formData?.education_level}
                onChange={(e: any, option: any) => {
                  onInput("education_level", option.key);
                }}
              />
              <StyledTextField
                disabled={!isEditable}
                label="Lični mjesečni prihodi"
                value={formData?.together_monthly_income}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
                name="together_monthly_income"
              />
              <StyledTextField
                disabled={!isEditable}
                label="Lična imovina"
                name="own_property"
                value={formData?.own_property}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
              />
              <DropdownWithOptionalField
                disabled={!isEditable}
                options={DropdownOptions.trueFalseOption}
                label="Pripadnik/ca druge društvene skupine"
                selectedKey={formData?.other_minority}
                showTextFieldOption="yes"
                onChange={(e: any, value: any) => {
                  onInput("other_minority", value.key ?? value);
                }}
              />
              <StyledDropdown
                disabled={!isEditable}
                label="Stambeni status"
                options={DropdownOptions.residentialOptions}
                selectedKey={formData?.residential_status}
                onChange={(e: any, option: any) => {
                  onInput("residential_status", option.key);
                }}
              />
              <SearchableDropdown
                disabled={!isEditable}
                label="Djeca"
                searchBy="victim"
                selectedKey={null}
                onChange={(e: any, value: any) => {
                  onChildSelection(value);
                }}
              />
              <StyledTextField
                multiline
                rows={4}
                name="sd_data_aditional"
                disabled={!isEditable}
                label="Komentar"
                value={formData?.sd_data_aditional}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
              />

              <Stack style={{ width: "620px" }}>
                <Label style={style.label}>Djeca</Label>
                <DetailsList
                  styles={{ headerWrapper: { marginTop: "-16px" } }}
                  selectionMode={SelectionMode.none}
                  items={childData ?? []}
                  onRenderItemColumn={(item, index, column) =>
                    onRender(item, index, column, "/victim/")
                  }
                  columns={
                    !isEditable
                      ? Columns().ChildrenColumns.slice(0, 1)
                      : Columns().ChildrenColumns
                  }
                />
              </Stack>
            </Stack>
          </PivotItem>
          <PivotItem headerText="Zaštitne mjere" style={style.pivotContainer}>
            <Stack
              horizontal
              style={{ maxWidth: "1000px" }}
              tokens={{ childrenGap: 20 }}
              wrap
              enableScopedSelectors
            >
              <StyledTextField
                disabled={!isEditable}
                label="Vrsta mjere"
                value={formData?.safety_measure}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
                name="safety_measure"
              />
              <StyledTextField
                disabled={!isEditable}
                label="Nadležni sud koji je izrekao mjeru"
                name="court"
                value={formData?.court}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
              />
              <StyledTextField
                disabled={!isEditable}
                label="Broj rješenja suda"
                value={formData?.case_number}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
                name="case_number"
              />
              <StyledDatePicker
                disabled={!isEditable}
                value={
                  formData?.created_court_case_date !== undefined &&
                  formData?.created_court_case_date !== null
                    ? new Date(
                        moment(
                          formData.created_court_case_date,
                          "DD.MM.YYYY"
                        ).format()
                      )
                    : undefined
                }
                label="Datum donošenja rješenja od strane sude"
                onSelectDate={(date: any) => {
                  onInput("safety_date", moment(date).format("DD.MM.YYYY"));
                }}
              />

              <StyledTextField
                disabled={!isEditable}
                label="Trajanje mjere"
                value={formData?.case_duration}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
                name="case_duration"
              />

              <StyledDropdown
                disabled={!isEditable}
                label="Podnosilac zahtjeva za izricanje mjere"
                selectedKey={formData?.applicant}
                options={DropdownOptions.methodOptions}
                onChange={(e: any, option: any) => {
                  onInput("applicant", option.key);
                }}
              />
              <StyledCommentField
                multiline
                rows={4}
                name="protective_measures_notes"
                disabled={!isEditable}
                label="Komentar"
                value={formData?.protective_measures_notes}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
              />
            </Stack>
          </PivotItem>
          <PivotItem
            headerText="Podaci o preživjelom nasilju"
            style={style.pivotContainer}
          >
            <Stack
              horizontal
              style={{ maxWidth: "1000px" }}
              tokens={{ childrenGap: 20 }}
              wrap
              enableScopedSelectors
            >
              <StyledDatePicker
                disabled={!isEditable}
                value={
                  formData?.violence_date !== undefined &&
                  formData?.violence_date !== null
                    ? new Date(
                        moment(formData.violence_date, "DD.MM.YYYY").format()
                      )
                    : undefined
                }
                label="Datum posljednjeg preživjelog nasilja"
                onSelectDate={(date: any) => {
                  onInput("violence_date", moment(date).format("DD.MM.YYYY"));
                }}
              />
              <StyledTextField
                disabled={!isEditable}
                label="Vrijeme i mjesto čina nasilja"
                value={formData?.violence_location}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
                name="violence_location"
              />
              <StyledDropdown
                disabled={!isEditable}
                options={DropdownOptions.violenceTypeOptions}
                selectedKeys={formData?.violence_type ?? []}
                label="Vrsta nasilja"
                multiSelect
                onChange={(e: any, option: any) => {
                  handleMultiSelect("violence_type", option);
                }}
              />
              <StyledDropdown
                disabled={!isEditable}
                label="Trajanje"
                options={DropdownOptions.violenceDurationOptions}
                selectedKey={formData?.violence_duration}
                onChange={(e: any, option: any) => {
                  onInput("violence_duration", option.key);
                }}
              />

              <StyledDropdown
                disabled={!isEditable}
                options={DropdownOptions.violenceConsequenceOptions}
                selectedKeys={formData?.violence_consequences ?? []}
                label="Posljedice nasilja"
                multiSelect
                onChange={(e: any, option: any) => {
                  handleMultiSelect("violence_consequences", option);
                }}
              />
              {/* <StyledDropdown
                disabled={ !isEditable }
                options={ DropdownOptions.trueFalseOption }
                selectedKey={ formData?.previous_report }
                label='Da li se žrtva obraćala nekoj od institucija'
                onChange={ (e: any, option: any) => { onInput('previous_report', option.key) } }
              /> */}
              <StyledDropdown
                disabled={!isEditable}
                multiselect
                label="Kojim institucijama se obraćala"
                selectedKeys={formData?.previous_report_institution ?? []}
                options={DropdownOptions.institutionOptions}
                multiSelect
                onChange={(e: any, option: any) => {
                  handleMultiSelect("previous_report_institution", option);
                }}
              />

              <StyledTextField
                disabled={!isEditable}
                label="Pomoć koja je dobijena od institucija"
                multiline
                rows={4}
                name="help_provided"
                value={formData?.help_provided}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
              />
              <StyledTextField
                disabled={!isEditable}
                label="Opis nasilja"
                multiline
                rows={4}
                name="violence_description"
                placeholder="Prethodna iskustva, učestalost, drugo..."
                value={formData?.violence_description}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
              />
              <StyledDropdown
                disabled={!isEditable}
                options={DropdownOptions.violenceCauseOptions}
                selectedKeys={formData?.violence_cause ?? []}
                label="Mogući uzroci nasilja"
                multiSelect
                onChange={(e: any, option: any) => {
                  handleMultiSelect("violence_cause", option);
                }}
              />
            </Stack>
          </PivotItem>
          <PivotItem
            headerText="Sigurnosni rizici"
            style={style.pivotContainer}
          >
            <Stack
              horizontal
              style={{ maxWidth: "1000px" }}
              tokens={{ childrenGap: 20 }}
              wrap
              enableScopedSelectors
            >
              <StyledDropdown
                disabled={!isEditable}
                label="Da li počinilac nasilja posjeduje oružje"
                selectedKey={formData?.question1}
                options={[
                  ...DropdownOptions.trueFalseOption,
                  {
                    text: "Nepoznato",
                    key: "unknown",
                  },
                ]}
                onChange={(e: any, option: any) => {
                  onInput("question1", option.key);
                }}
              />
              <StyledDropdown
                disabled={!isEditable}
                label="Da li počinilac prijeti ubistvom/samoubistvom"
                selectedKey={formData?.question2}
                options={DropdownOptions.trueFalseOption}
                onChange={(e: any, option: any) => {
                  onInput("question2", option.key);
                }}
              />
              <StyledDropdown
                disabled={!isEditable}
                label="Da li žrtva doživljava strah od počinioca"
                selectedKey={formData?.question3}
                options={DropdownOptions.trueFalseOption}
                onChange={(e: any, option: any) => {
                  onInput("question3", option.key);
                }}
              />
              <StyledDropdown
                disabled={!isEditable}
                label="Procjena rizika od strane stručnog tima"
                selectedKey={formData?.question4}
                options={DropdownOptions.riskAssesmentOptions}
                onChange={(e: any, option: any) => {
                  onInput("question4", option.key);
                }}
              />
              <StyledDropdown
                disabled={!isEditable}
                label="Procjena rizika od strane nadležne institucije"
                selectedKey={formData?.question5}
                options={DropdownOptions.riskAssesmentOptions}
                onChange={(e: any, option: any) => {
                  onInput("question5", option.key);
                }}
              />

              <Stack style={{ width: "940px" }}>
                <Label style={style.label}>
                  Prijetnje / uznemiravanje žrtve
                </Label>
              </Stack>
              <StyledTextField
                name="question6"
                disabled={!isEditable}
                label="Ime osobe koja vrši prijetnju"
                value={formData?.question6}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
              />
              <StyledDatePicker
                disabled={!isEditable}
                value={
                  formData?.safety_date !== undefined &&
                  formData?.safety_date !== null
                    ? new Date(
                        moment(formData.safety_date, "DD.MM.YYYY").format()
                      )
                    : undefined
                }
                label="Datum prijetnje / uznemiravanja"
                onSelectDate={(date: any) => {
                  onInput("safety_date", moment(date).format("DD.MM.YYYY"));
                }}
              />
              <StyledTextField
                disabled={!isEditable}
                label="Način prijetnje / uznemiravanja"
                value={formData?.safety_type}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
                name="safety_type"
              />
              <StyledTextField
                disabled={!isEditable}
                label="Mjesto prijetnje / uznemiravanja"
                value={formData?.safety_place}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
                name="safety_place"
              />
            </Stack>
          </PivotItem>
          <PivotItem
            headerText="Ostala opažanja i primjedbe"
            style={style.pivotContainer}
          >
            <Stack
              horizontal
              style={{ maxWidth: "1000px" }}
              tokens={{ childrenGap: 20 }}
              wrap
              enableScopedSelectors
            >
              <StyledCommentField
                disabled={!isEditable}
                value={formData?.notes}
                label="Napomena / komentar"
                multiline
                rows={8}
                onChange={(e: any, value: any) => {
                  onInput(e.target.name, value);
                }}
                name="notes"
              />
            </Stack>
          </PivotItem>
        </Pivot>
      </Stack>
      <Stack horizontal style={style.row} tokens={{ childrenGap: 30 }}>
        <StyledButton
          disabled={canSubmit()}
          onClick={() => {
            !isEditable ? setIsEditable(true) : onSubmit();
          }}
        >
          {actionInProgress ? <Spinner /> : isEditable ? "Sačuvaj" : "Izmjeni"}
        </StyledButton>
        <StyledButton
          red
          disabled={!isEditable || actionInProgress || id === undefined}
          onClick={() => {
            setActionInProgress(true);
            deleteVictim(id)
              .then(() => {
                setMessage({
                  type: MessageBarType.success,
                  text: "Unos obrisan.",
                });
                setTimeout(() => {
                  navigate("/victim");
                }, 1500);
              })
              .catch(() => {
                setActionInProgress(false);
                setMessage({
                  type: MessageBarType.error,
                  text: "Greška. Pokušajte ponovo.",
                });
              });
          }}
        >
          {actionInProgress ? <Spinner /> : "Obriši"}
        </StyledButton>
      </Stack>
      {id !== undefined && (
        <Stack>
          <Stack style={style.tablesHeader}>Datoteke i pružene usluge:</Stack>
          <Stack tokens={{ childrenGap: 15 }}>
            {fileMessage.text.length > 0 ? (
              <MessageBar
                onDismiss={() => {
                  setFileMessage({ ...fileMessage, text: "" });
                }}
                messageBarType={fileMessage.type}
                styles={{
                  root: {
                    width: "100%",
                    maxWidth: "1000px",
                  },
                }}
              >
                {fileMessage.text}
              </MessageBar>
            ) : (
              <div style={{ height: "32px" }} />
            )}

            <Pivot style={{ textAlign: "left", paddingBottom: "250px" }}>
              <PivotItem headerText="Datoteke" style={{ maxWidth: "1000px" }}>
                <Stack tokens={{ childrenGap: 15 }}>
                  <Stack
                    horizontal
                    style={style.row}
                    tokens={{ childrenGap: 30 }}
                  >
                    <Stack style={{ paddingTop: "15px" }}>
                      <Stack
                        horizontal
                        style={style.topRow}
                        horizontalAlign="space-between"
                      >
                        <PageNavigation
                          pagination={pagination}
                          onPageChange={onPageChange}
                          onSearch={onSearch}
                        />
                        <Dropzone
                          onDrop={(e) => {
                            setUploadInProgress(true);
                            const formData = new FormData();
                            formData.append("victim_id", id ?? "");
                            formData.append("uploaded_file", e[0]);
                            formData.append("file_type", e[0].type);
                            uploadFile(formData)
                              .then((res) => {
                                const temp = filesList ?? [];
                                setFilesList([res.data, ...temp]);
                                setFilteredData([res.data, ...filteredData]);
                                setUploadInProgress(false);
                                setFileMessage({
                                  type: MessageBarType.success,
                                  text: "Datoteka uspiješno sačuvana.",
                                });
                                setTimeout(() => {
                                  setFileMessage({
                                    type: MessageBarType.info,
                                    text: "",
                                  });
                                }, 1500);
                              })
                              .catch((err) => {
                                setUploadInProgress(false);
                                setFileMessage({
                                  type: MessageBarType.error,
                                  text: err.message,
                                });
                              });
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <StyledButton disabled={uploadInProgress}>
                                  {uploadInProgress ? <Spinner /> : "Dodaj"}
                                </StyledButton>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </Stack>
                      {actionInProgress ? (
                        <Spinner style={{ paddingTop: "50px" }} />
                      ) : (
                        <>
                          {" "}
                          <DetailsList
                            onRenderItemColumn={(item, index, column) =>
                              onRender(item, index, column, "/")
                            }
                            items={filteredData ?? []}
                            columns={Columns().FileColumns}
                            selectionMode={SelectionMode.none}
                          />
                          {filteredData?.length === 0 && (
                            <Stack horizontal horizontalAlign="center">
                              nema datoteka
                            </Stack>
                          )}
                        </>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </PivotItem>
              <PivotItem headerText="Sos poziv" style={{ maxWidth: "1000px" }}>
                <DetailsList
                  selectionMode={SelectionMode.none}
                  columns={Columns().SosCallColumns}
                  onRenderItemColumn={(item, index, column) =>
                    onRender(item, index, column, "/services/soscall/")
                  }
                  items={sosCallData ?? []}
                />
                {sosCallData?.length === 0 && (
                  <Stack
                    horizontal
                    horizontalAlign="center"
                    style={{ userSelect: "none" }}
                  >
                    nema podataka
                  </Stack>
                )}
              </PivotItem>
              <PivotItem
                headerText="Sigurna kuća"
                style={{ maxWidth: "1000px" }}
              >
                <DetailsList
                  selectionMode={SelectionMode.none}
                  columns={Columns().SafehouseColumns}
                  onRenderItemColumn={(item, index, column) =>
                    onRender(item, index, column, "/services/safehouse/")
                  }
                  items={safehouseData ?? []}
                />
                {safehouseData?.length === 0 && (
                  <Stack
                    horizontal
                    horizontalAlign="center"
                    style={{ userSelect: "none" }}
                  >
                    nema podataka
                  </Stack>
                )}
              </PivotItem>
              <PivotItem
                headerText="Savjetodavne usluge"
                style={{ maxWidth: "1000px" }}
              >
                <DetailsList
                  selectionMode={SelectionMode.none}
                  columns={Columns().AdvisoryHelpColumns}
                  onRenderItemColumn={(item, index, column) =>
                    onRender(item, index, column, "/services/advisoryhelp/")
                  }
                  items={advisoryHelpData ?? []}
                />
                {advisoryHelpData?.length === 0 && (
                  <Stack
                    horizontal
                    horizontalAlign="center"
                    style={{ userSelect: "none" }}
                  >
                    nema podataka
                  </Stack>
                )}
              </PivotItem>
              <PivotItem
                headerText="Lice od povjerenja"
                style={{ maxWidth: "1000px" }}
              >
                <DetailsList
                  selectionMode={SelectionMode.none}
                  columns={Columns().TrustPersonColumns}
                  onRenderItemColumn={(item, index, column) =>
                    onRender(item, index, column, "/services/trustperson/")
                  }
                  items={trustPersonData ?? []}
                />
                {trustPersonData?.length === 0 && (
                  <Stack
                    horizontal
                    horizontalAlign="center"
                    style={{ userSelect: "none" }}
                  >
                    nema podataka
                  </Stack>
                )}
              </PivotItem>
            </Pivot>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
