import { Label, MessageBar, MessageBarType, Spinner, Stack } from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import { getProperties } from '../../assets/axios/services'
import { SettingsStyle } from './Settings.style'

export default function SettingsForm () {
  const style = SettingsStyle()
  const [location, setLocation] = useState('')
  const [version, setVersion] = useState('')
  const [date, setDate] = useState('')
  const [message, setMessage] = useState({ type: MessageBarType.success, text: '' })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getProperties('location').then((res) => {
      setLocation(res.data.value)
      getProperties('version').then((res) => {
        setVersion(res.data.value)
        getProperties('date').then((res) => {
          setDate(res.data.value)
          setLoading(false)
        })
      })
    }).catch(() => {
      setLoading(false)
      setMessage({ type: MessageBarType.error, text: 'Podaci o aplikaciji nisu uneseni.' })
    })
  }, [])

  return (
    <Stack tokens={{ childrenGap: 50 }}>
      <Stack tokens={{ childrenGap: 15 }}>
        { message.text.length > 0
          ? <MessageBar
          onDismiss={ () => { setMessage({ ...message, text: '' }) } }
          messageBarType={ message.type }
          styles={ style.messageBar }>
            { message.text }
          </MessageBar>
          : <div style={{ height: '32px' }}/>
        }
        { loading
          ? <Stack style={{ width: '1000px', height: '61px' }}
        verticalAlign='center'
        >
            <Spinner/>
          </Stack>
          : <Stack horizontal style={{ maxWidth: '1000px' }} tokens={{ childrenGap: 20 }} wrap enableScopedSelectors>
            <Stack style={{ width: '300px' }}>
              <Label style={ style.label }>Lokacija</Label>
              <span style={ style.labeledText }>
                { location }
              </span>
            </Stack>
            <Stack style={{ width: '300px' }}>
              <Label style={ style.label }>Verzija</Label>
              <span style={ style.labeledText }>
                { version }
              </span>
            </Stack>
            <Stack style={{ width: '300px' }}>
              <Label style={ style.label }>Datum</Label>
              <span style={ style.labeledText }>
                { date }
              </span>
            </Stack>
          </Stack> }
      </Stack>
    </Stack>
  )
}
