import { verify } from 'jsonwebtoken'
import React from 'react'
import { Navigate } from 'react-router-dom'

export const ProtectedRoute = ({ component }: any) => {
  let element = component

  if (!sessionStorage.getItem('token')) {
    element = <Navigate to='/'/>
  }

  verify(JSON.parse(sessionStorage.getItem('token') ?? '{}'), 'SOMETHINGRANDOM', (err: any, res: any) => {
    if (err) { element = <Navigate to='/'/> }
  }
  )

  return element
}
