import React from 'react'
import { useParams } from 'react-router-dom'
import MainContentContainer from '../../components/containers/MainContentContainer'
import SosCallForm from '../../components/sosCall/SosCallForm'

export default function SosCallFormPage () {
  const { id } = useParams()
  return (
    <MainContentContainer
        backButton
        title={ `${id === undefined ? 'Dodaj' : 'Izmjeni'} unos za uslugu - SOS poziv` }
        content={ <SosCallForm/> }
        />
  )
}
