/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/brace-style */
import { Stack, DetailsList, SelectionMode, Spinner } from '@fluentui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAllUsers } from '../../assets/axios/services'
import { Columns } from '../../assets/consts/Columns'
import PageNavigation from '../styledComponents/PageNavigation'
import StyledButton from '../styledComponents/StyledButton'
import { UserStyle } from './User.style'

export default function UserList () {
  const style = UserStyle()
  const navigate = useNavigate()
  const [pagination, setPagination] = useState({})
  const [actionInProgress, setActionInProgress] = useState(false)

  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])

  const onPageChange = (page: any) => {
    setActionInProgress(true)
    getAllUsers(page).then((res) => {
      setData(res.data.items)
      setFilteredData(res.data.items)
      setPagination({
        total: res.data.total,
        page: res.data.page,
        size: res.data.size
      })
      setActionInProgress(false)
    }
    )
  }
  useEffect(() => {
    onPageChange(1)
  }, [])

  const onSearch = (searchTerm: any) => {
    if (searchTerm.length > 2) {
      setFilteredData(data
        .filter((item: any) => item.first_name.toLowerCase().match(searchTerm.toLowerCase()) ||
        item.last_name.toLowerCase().match(searchTerm.toLowerCase())
        ))
    }
    else if (searchTerm === '') { setFilteredData(data) }
  }

  const onRender = (item: any, index: any, column: any) => {
    if (column != null) {
      switch (column?.key) {
        case 'role':
          return (<>{ item[column?.key] === 'admin' ? 'Administrator' : 'Korisnik' }</>)

        case 'date_created':
          return (<>{ item.date_created ? moment(item.date_created).format('DD.MM.YYYY') : '' }</>)

        default:
          return (<>{ item[column?.key] }</>)
      }
    }
  }

  return (
    <Stack style={{ maxWidth: '1000px' }}>
      <Stack horizontal style={ style.topRow } horizontalAlign='space-between'>
        <PageNavigation pagination={ pagination } onPageChange={ onPageChange } onSearch={ onSearch }/>
        <StyledButton
        text='Dodaj'
        onClick={ () => { navigate('/user/new') } }
        />
      </Stack>
      { actionInProgress
        ? <Spinner style={{ paddingTop: '50px' }}/>
        : <> <DetailsList
      onRenderItemColumn={ onRender }
      items={ filteredData }
      columns={ Columns().UserColumns }
      selectionMode={ SelectionMode.none }
      />
          { filteredData.length === 0 &&
            <Stack horizontal horizontalAlign='center' style={{ userSelect: 'none' }}>
              nema podataka
            </Stack> }
        </>
      }
    </Stack>
  )
}
