import React from 'react'
import MainContentContainer from '../../components/containers/MainContentContainer'
import SosCallList from '../../components/sosCall/SosCallList'

export default function SosCallListView () {
  return (
    <MainContentContainer
        title={ 'Lista unosa za uslugu - SOS poziv' }
        content={ <SosCallList/> }
        />

  )
}
