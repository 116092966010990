import { COLORS } from '../../assets/theme/colors'

export const NavigationStyle = (active?: boolean) => ({
  sideContainer: {
    backgroundColor: COLORS.primary,
    width: '240px',
    minHeight: 'calc(100vh - 60px)',
    height: 'auto',
    paddingTop: '6px',
    boxShadow: `4px 0px 2px -2px ${COLORS.darkerGray}`

  },
  topContainer: {
    height: '60px',
    padding: '5px 20px',
    boxShadow: `0 4px 2px -2px ${COLORS.darkerGray}`
  },
  topNavButton: {
    root: {
      border: 'none'
    },
    rootHovered: {
      backgroundColor: 'transparent',
      color: COLORS.primary
    },
    rootPressed: {
      backgroundColor: 'transparent',
      color: COLORS.teal
    },
    rootExpanded: {
      backgroundColor: 'transparent'
    }
  },
  buttonStack: {
    padding: '12px 0',
    borderBottom: '3.5px solid',
    borderColor: COLORS.darker

  },
  sideButton: {
    root: {
      width: '240px',
      height: '36px',
      backgroundColor: active ? COLORS.darker : 'transparent',
      color: 'white',
      textAlign: 'left',
      borderLeft: active ? `2px solid ${COLORS.teal}` : 'none',
      border: 'none'
    },
    rootHovered: {
      backgroundColor: COLORS.darker,
      color: 'white',
      borderLeft: `2px solid ${COLORS.teal}`
    },
    rootChecked: {
      backgroundColor: COLORS.darker,
      color: 'white',
      borderLeft: `2px solid ${COLORS.teal}`
    },
    rootPressed: {
      backgroundColor: COLORS.teal,
      color: 'white'
    },
    label: {
      fontWeight: '200',
      marginBottom: '-3px'
    }
  },
  menuIcon: {
    root: {
      color: COLORS.primary
    }
  }
})
