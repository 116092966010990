/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-constant-condition */
import { IconButton, SearchBox, Stack } from '@fluentui/react'
import React from 'react'
import { COLORS } from '../../assets/theme/colors'

export default function PageNavigation ({ pagination, onPageChange, onSearch }: any) {
  const { page, total, size } = pagination

  const style = {
    container: {
      width: '80%',
      userSelect: 'none' as const
    },
    searchBox: {
      root: {
        width: '300px'
      },
      icon: COLORS.primary
    },
    pageNav: {
      width: '200px'
    },
    icon: {
      root: {
        color: COLORS.primary
      }
    },
    placeholderDiv: {
      width: '32px'
    },
    label: {
      color: COLORS.darkerGray,
      fontWeight: 'bold',
      fontSize: '14px'
    },
    number: {
      color: COLORS.primary,
      fontWeight: 'bold'
    }
  }

  return (
    <Stack
            horizontal
            verticalAlign='center'
            horizontalAlign='space-between'
            style={ style.container }
            tokens={{ childrenGap: 15 }}>

      <Stack horizontal verticalAlign='center'
                tokens={{ childrenGap: 15 }}>
        <SearchBox
        placeholder='Unesite pojam za pretragu...'
        showIcon
        onChange={ (e, value) => { onSearch(value) } }
        styles={ style.searchBox } />

        <Stack
                    horizontal
                    verticalAlign='center'
                    horizontalAlign='space-evenly'
                    style={{ width: '200px' }}
                >
          { page !== undefined && page !== 1
            ? <IconButton
            iconProps={{ iconName: 'ChevronLeftMed', styles: style.icon }}
            onClick={ () => { onPageChange(page - 1) } }
            />
            : <div style={ style.placeholderDiv } /> }
          <Stack
                        horizontal
                        verticalAlign='center'
                        tokens={{ childrenGap: 5 }}
                    >
            <span style={ style.label }>Stranica:</span>
            <span style={ style.number }>{ page }</span>
          </Stack>
          { total > (page * size)
            ? <IconButton
            iconProps={{ iconName: 'ChevronRightMed', styles: style.icon }}
            onClick={ () => { onPageChange(page + 1) } }
            />
            : <div style={ style.placeholderDiv } /> }
        </Stack>
      </Stack>
      <Stack
                horizontal
                verticalAlign='center'
                tokens={{ childrenGap: 5 }}
            >
        <span style={ style.label }>Ukupno rezultata:</span>
        <span style={ style.number }>{ total }</span>
      </Stack>

    </Stack>
  )
}
