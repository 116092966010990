/* eslint-disable */
import React, { useEffect, useState } from "react";
import MainContentContainer from "../../components/containers/MainContentContainer";
import { MessageBar, MessageBarType, Spinner, Stack } from "@fluentui/react";
import * as XLSX from "xlsx";
import StyledDatePicker from "../../components/styledComponents/StyledDatePicker";
import moment from "moment";
import StyledDropdown from "../../components/styledComponents/StyledDropdown";
import {
  getAdvisoryDataTypes,
  getAdvisoryServicesReport,
  getSafehouseHelpReport,
  getSafehouseHelpTotalDaysReport,
  getSafehouseHelpTotalDaysVictimReport,
  getSafehouseHelpVictimReport,
  getSafetyMeasuresReport,
} from "../../assets/axios/services";
import SearchableDropdown from "../../components/styledComponents/SearchableDropdown";
import { ReportsStyle } from "./reports.style";
import { isEditable } from "@testing-library/user-event/dist/utils";
import StyledButton from "../../components/styledComponents/StyledButton";

export default function ReportsPage() {
  const [formData, setFormData] = useState<any>(null);
  const [helpDropdownData, setHelpDropdownData] = useState<any>([]);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [victimName, setVictimName] = useState("");

  const style = ReportsStyle();
  const onInput = (fieldName: string, value: any) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const reportDropdownData = [
    {
      key: "advisoryServices",
      text: "Savjetodavna pomoć",
    },
    {
      key: "safehouseHelp",
      text: "Pomoć u sigurnoj kući",
    },
    {
      key: "safehouseHelpVictim",
      text: "Pomoć u sigurnoj kući po korisniku",
    },
    {
      key: "totalDays",
      text: "Broj dana provedenih u sigurnoj kući",
    },
    {
      key: "totalDaysVictim",
      text: "Broj dana provedenih u sigurnoj kući po korisniku",
    },
    {
      key: "safetyMeasures",
      text: "Izrečene zaštitne mjere",
    },
  ];

  const onError = (err: any) => {
    setActionInProgress(false);
    setMessage({ type: MessageBarType.error, text: err.message });
  };

  const onSuccess = () => {
    setMessage({
      text: "Izvještaj generisan.",
      type: MessageBarType.success,
    });
    setVictimName("");
    setFormData(null);
    setActionInProgress(false);
    setTimeout(() => {
      setMessage({ type: MessageBarType.success, text: "" });
    }, 1500);
  };
  const onSubmit = () => {
    setActionInProgress(true);

    switch (formData?.report_type) {
      case "advisoryServices":
        getAdvisoryServicesReport(
          formData?.start_date,
          formData?.end_date,
          formData?.help_type
        )
          .then((res) => {
            const sheetData = res.data.results.map((item: any) => ({
              Tip: item.type,
              "Broj usluga": item.number,
            }));

            const ws = XLSX.utils.json_to_sheet(sheetData);

            ws["!cols"] = [{ wch: 30 }, { wch: 10 }];

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Usluge");

            XLSX.writeFile(
              wb,
              `Savjetodavne usluge ${formData?.start_date} - ${formData?.end_date}.xlsx`
            );
            onSuccess();
          })
          .catch((err) => onError(err));
        break;
      case "safehouseHelp":
        getSafehouseHelpReport(
          formData?.start_date,
          formData?.end_date,
          formData?.help_type
        )
          .then((res) => {
            const flatData = res.data.results.flatMap((item: any) =>
              item.subTypes
                .map((subType: any) => ({
                  Tip: `${item.type} - ${subType.type}`,
                  "Broj usluga": subType.number,
                }))
                .flat()
            );

            const sheetData = [
              ...flatData,
              {
                Tip: "Ukupno",
                "Broj usluga": flatData.reduce(
                  (acc: number, item: any) => (acc += item["Broj usluga"]),
                  0
                ),
              },
            ];

            const ws = XLSX.utils.json_to_sheet(sheetData);

            ws["!cols"] = [{ wch: 50 }, { wch: 10 }];

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Usluge");

            XLSX.writeFile(
              wb,
              `Usluge u okviru sigurne kuće ${formData?.start_date} - ${formData?.end_date}.xlsx`
            );
            onSuccess();
          })
          .catch((err) => onError(err));
        break;
      case "safehouseHelpVictim":
        getSafehouseHelpVictimReport(
          formData?.start_date,
          formData?.end_date,
          formData?.help_type,
          formData?.victim_id
        )
          .then((res) => {
            const flatData = res.data.results.flatMap((item: any) =>
              item.subTypes
                .map((subType: any) => ({
                  Tip: `${item.type} - ${subType.type}`,
                  "Broj usluga": subType.number,
                }))
                .flat()
            );

            const sheetData = [
              ...flatData,
              {
                Tip: "Ukupno",
                "Broj usluga": flatData.reduce(
                  (acc: number, item: any) => (acc += item["Broj usluga"]),
                  0
                ),
              },
            ];

            const ws = XLSX.utils.json_to_sheet(sheetData);

            ws["!cols"] = [{ wch: 50 }, { wch: 10 }];

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Usluge");

            XLSX.writeFile(
              wb,
              `Usluge u okviru sigurne kuće - ${victimName} ${formData?.start_date} - ${formData?.end_date}.xlsx`
            );
            onSuccess();
          })
          .catch((err) => onError(err));
        break;
      case "totalDays":
        getSafehouseHelpTotalDaysReport(
          formData?.start_date,
          formData?.end_date
        )
          .then((res) => {
            getSafehouseHelpTotalDaysReport(
              formData?.start_date,
              formData?.end_date
            ).then((res) => {
              const sheetData = [
                {
                  "Ukupan broj dana": res.data.totalDays,
                  "Ukupan broj boravaka": res.data.totalEntries,
                  Žene: res.data.genderCounts.female,
                  Muškarci: res.data.genderCounts.male,
                  Nepoznato: res.data.genderCounts.null,
                },
              ];

              const ws = XLSX.utils.json_to_sheet(sheetData);

              ws["!cols"] = [
                { wch: 25 },
                { wch: 25 },
                { wch: 10 },
                { wch: 10 },
                { wch: 15 },
              ];

              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, "Pregled");

              XLSX.writeFile(
                wb,
                `Ukupan broj dana provedenih u sigurnoj kući ${formData?.start_date} - ${formData?.end_date}.xlsx`
              );
              onSuccess();
            });
          })
          .catch((err) => onError(err));
        break;
      case "totalDaysVictim":
        getSafehouseHelpTotalDaysVictimReport(
          formData?.start_date,
          formData?.end_date,
          formData?.victim_id
        )
          .then((res) => {
            const sheetData = [
              {
                "Ukupan broj dana": res.data.totalDays,
                "Ukupan broj boravaka": res.data.totalEntries,
              },
            ];

            const ws = XLSX.utils.json_to_sheet(sheetData);

            ws["!cols"] = [{ wch: 25 }, { wch: 25 }];

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Pregled");

            XLSX.writeFile(
              wb,
              `Ukupan broj dana provedenih u sigurnoj kući - ${victimName} ${formData?.start_date} - ${formData?.end_date}.xlsx`
            );
            onSuccess();
          })
          .catch((err) => onError(err));
        break;
      case "safetyMeasures":
        getSafetyMeasuresReport()
          .then((res) => {
            const formattedData: any = {
              "Broj počinialaca nasilja koji posjeduju oružje":
                res.data.noOfPerpetratorsWithWeapon,
              "Visok rizik (procjena tima)": res.data.riskAssessmentByTeam.high,
              "Srednji rizik (procjena tima)":
                res.data.riskAssessmentByTeam.moderate,
              "Nizak rizik (procjena tima)": res.data.riskAssessmentByTeam.low,
              "Visok rizik (procjena institucija)":
                res.data.riskAssessmentByInstitution.high,
              "Srednji rizik (procjena institucija)":
                res.data.riskAssessmentByInstitution.moderate,
              "Nizak rizik (procjena institucija)":
                res.data.riskAssessmentByInstitution.low,
              "Nepoznat stepen rizika":
                res.data.riskAssessmentByTeam.unknown +
                res.data.riskAssessmentByInstitution.unknown,
            };

            const sheetData = Object.keys(formattedData).map((key) => ({
              Kategorija: key,
              Vrijednost: formattedData[key],
            }));

            const ws = XLSX.utils.json_to_sheet(sheetData);

            ws["!cols"] = [{ wch: 50 }, { wch: 10 }];

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Pregled");

            XLSX.writeFile(wb, `Procjene rizika i sigurnosne mjere.xlsx`);
            onSuccess();
          })
          .catch((err) => onError(err));
        break;
    }
  };

  useEffect(() => {
    getAdvisoryDataTypes().then((res) => {
      const options = [
        {
          key: "ALL",
          text: "Svi tipovi pomoći",
        },
      ];

      res.data.items.map((item: any) =>
        options.push({
          key: item.type,
          text: item.type,
        })
      );
      setHelpDropdownData(options);
    });
  }, []);

  const [message, setMessage] = useState({
    type: MessageBarType.success,
    text: "",
  });

  return (
    <MainContentContainer
      title={`Generisanje izvještaja`}
      content={
        <Stack tokens={{ childrenGap: 50 }}>
          <Stack tokens={{ childrenGap: 15 }}>
            {message.text.length > 0 ? (
              <MessageBar
                onDismiss={() => {
                  setMessage({ ...message, text: "" });
                }}
                messageBarType={message.type}
                styles={style.messageBar}
              >
                {message.text}
              </MessageBar>
            ) : (
              <div style={{ height: "32px" }} />
            )}
            <Stack
              horizontal
              style={{ maxWidth: "1000px" }}
              tokens={{ childrenGap: 20 }}
              wrap
              enableScopedSelectors
            >
              <Stack
                horizontal
                style={{ maxWidth: "1000px" }}
                tokens={{ childrenGap: 20 }}
                wrap
                enableScopedSelectors
              >
                <StyledDatePicker
                  label="Početni datum"
                  value={
                    formData?.start_date
                      ? new Date(
                          moment(formData?.start_date, "DD.MM.YYYY").format()
                        )
                      : undefined
                  }
                  onSelectDate={(date: any) => {
                    onInput("start_date", moment(date).format("DD.MM.YYYY"));
                  }}
                />
                <StyledDatePicker
                  label="Krajnji datum"
                  value={
                    formData?.end_date
                      ? new Date(
                          moment(formData?.end_date, "DD.MM.YYYY").format()
                        )
                      : undefined
                  }
                  onSelectDate={(date: any) => {
                    onInput("end_date", moment(date).format("DD.MM.YYYY"));
                  }}
                />

                <StyledDropdown
                  label="Tip izvještaja"
                  selectedKey={formData?.report_type ?? null}
                  options={reportDropdownData}
                  onChange={(e: any, option: any) => {
                    onInput("report_type", option.key);
                  }}
                />

                {[
                  "advisoryServices",
                  "safehouseHelp",
                  "safehouseHelpVictim",
                ].includes(formData?.report_type) && (
                  <StyledDropdown
                    label="Tip pomoći"
                    value={formData?.help_type ?? undefined}
                    options={helpDropdownData}
                    onChange={(e: any, option: any) => {
                      onInput("help_type", option.key);
                    }}
                  />
                )}

                {["safehouseHelpVictim", "totalDaysVictim"].includes(
                  formData?.report_type
                ) && (
                  <SearchableDropdown
                    label="Žrtva nasilja"
                    searchBy="victim"
                    onChange={(e: any, value: any) => {
                      setVictimName(value.text);
                      onInput("victim_id", value.key);
                    }}
                  />
                )}
              </Stack>
              <Stack
                horizontal
                horizontalAlign="start"
                tokens={{ childrenGap: 30 }}
              >
                <StyledButton
                  disabled={
                    formData?.start_date === undefined ||
                    formData?.end_date === undefined ||
                    formData?.report_type === undefined ||
                    ([
                      "advisoryServices",
                      "safehouseHelp",
                      "safehouseHelpVictim",
                    ].includes(formData?.report_type) &&
                      formData?.help_type === undefined) ||
                    (["safehouseHelpVictim", "totalDaysVictim"].includes(
                      formData?.report_type
                    ) &&
                      formData?.victim_id === undefined) ||
                    actionInProgress
                  }
                  onClick={onSubmit}
                >
                  {actionInProgress ? <Spinner /> : "Generiši izvještaj"}
                </StyledButton>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      }
    />
  );
}
