import React from 'react'
import { useParams } from 'react-router-dom'
import MainContentContainer from '../../components/containers/MainContentContainer'
import TrustPersonForm from '../../components/trustPerson/TrustPersonForm'

export default function TrustPersonFormPage () {
  const { id } = useParams()
  return (
    <MainContentContainer
      backButton
      title={ `${id === undefined ? 'Dodaj' : 'Izmjeni'} unos za uslugu - Osoba od povjerenja` }
      content={ <TrustPersonForm /> }
    />
  )
}
