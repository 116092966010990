import React from 'react'
import AdvisoryHelpList from '../../components/advisoryHelp/AdvisoryHelpList'
import MainContentContainer from '../../components/containers/MainContentContainer'

export default function AdvisoryHelpListView () {
  return (
    <MainContentContainer
        title={ 'Lista unosa za uslugu - Savjetodavna pomoć' }
        content={ <AdvisoryHelpList/> }
        />

  )
}
