/* eslint-disable brace-style */
import { Dropdown, DropdownMenuItemType, SearchBox, Spinner, Stack } from '@fluentui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { searchPersons } from '../../assets/axios/services'
import { COLORS } from '../../assets/theme/colors'
import StyledButton from './StyledButton'
import { StyledComponentsStyle } from './StyledComponents.style'

export default function SearchableDropdown ({ searchBy, ...rest }: any) {
  const [data, setData] = useState<any>([])
  const navigate = useNavigate()

  const style = StyledComponentsStyle()

  function generateOptions (rawData: any) {
    return rawData.map((item: any) => ({
      text: `${item.first_name} ${item.last_name}`,
      key: item.id
    }))
  }

  function onSearch (value: string | undefined) {
    if (searchTimeout !== null) clearTimeout(searchTimeout)

    searchTimeout = setTimeout(() => {
      if (value != null) {
        if (value.length > 2) {
          setData([{ key: 'loader', text: '', itemType: DropdownMenuItemType.Header }])
          searchPersons(value, searchBy).then((res) => {
            if (res.data.length > 0) {
              setData(generateOptions(res.data))
            } else {
              setData([{ key: 'noData', text: '', itemType: DropdownMenuItemType.Header }])
            }
          })
        } else {
          setData([{ key: 'tooShort', text: '', itemType: DropdownMenuItemType.Header }])
        }
      }
    }, 1000)
  }

  let searchTimeout: any = null

  function renderOption (option: any): JSX.Element {
    let renderedOption = <></>
    switch (option.key) {
      case 'forSearch':
        renderedOption =
          <Stack verticalAlign='center' style={ style.headerField }>
            <SearchBox
            underlined={ true }
            placeholder="Unesite ime, prezime ili matični broj..."
            onChange={ (e, value) => { onSearch(value) } }
            showIcon={ true }
            iconProps={{
              styles: {
                root: {
                  color: COLORS.primary
                }
              }
            }}
        />
          </Stack>
        break
      case 'loader':
        renderedOption =
          <Stack horizontal horizontalAlign='center' style={ style.headerField }>
            <Spinner />
          </Stack>

        break
      case 'tooShort':
        renderedOption =
          <Stack horizontal horizontalAlign='center' style={ style.headerField }>
            <span style={ style.tooShort }>Potrebno je unijeti najmanje 3 karaktera za pretragu</span>
          </Stack>

        break
      case 'noData':
        renderedOption =
          <Stack
          horizontal
          horizontalAlign='space-between'
          verticalAlign='center'
          style={ style.noData }>
            <span style={ style.noDataText }>Tražena osoba ne postoji u bazi:</span>
            <StyledButton width='100px' height='30px' text='Dodaj' onClick={ () => { navigate(`/${searchBy}/new`) } }/>
          </Stack>
        break
      default:
        renderedOption = <>{ option.text }</>
    }

    return renderedOption
  }

  return (
    <Dropdown
    placeholder='Kliknite za pretragu...'
    styles={ style.searchableDropdown }
    { ...rest }
    onRenderOption={ (option) => { return renderOption(option) } }
    options={ [
      { key: 'forSearch', text: '', itemType: DropdownMenuItemType.Header },
      ...data
    ] }
        />
  )
}
