/* eslint-disable brace-style */
/* eslint-disable no-console */
import { Stack } from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import StyledDropdown from './StyledDropdown'
import StyledTextField from './StyledTextField'

export default function DropdownWithOptionalField ({ showTextFieldOption, ...rest }: any) {
  const [selectedKey, setSelectedKey] = useState(rest.selectedKey)

  useEffect(() => {
    if (rest.selectedKey !== null && rest.selectedKey !== undefined) {
      if (rest.options.filter((item: any) => item.key === rest.selectedKey).length === 0
      ) {
        setSelectedKey(showTextFieldOption)
      } else {
        setSelectedKey(rest.selectedKey)
      }
    }
  }, [rest.selectedKey])

  return <Stack style={{ margin: '10px' }} tokens={{ childrenGap: 5 }} >
    <StyledDropdown
    disabled={ rest.disabled }
    label={ rest.label }
    selectedKey={ selectedKey }
      options={ rest.options }
      onChange={ rest.onChange }
    />
    { selectedKey === showTextFieldOption &&
      <StyledTextField
      disabled={ rest.disabled }
    placeholder={ `${rest.label}...` }
    value={ rest.selectedKey === showTextFieldOption ? '' : rest.selectedKey }
    onChange={ rest.onChange }
      /> }
  </Stack>
}
