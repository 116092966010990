import React from 'react'
import MainContentContainer from '../components/containers/MainContentContainer'
import ServiceTypes from '../components/serviceTypes/ServiceTypes'

export default function ServicesTypesPage () {
  return (
    <MainContentContainer
        title={ 'Usluge' }
        content={ <ServiceTypes/> }
        />

  )
}
