/* eslint-disable brace-style */
import React, { useState } from 'react'
import { MessageBar, MessageBarType, Spinner, Stack } from '@fluentui/react'
import StyledButton from '../components/styledComponents/StyledButton'
import { getUserInfo, login } from '../assets/axios/services'
import { useNavigate } from 'react-router-dom'
import StyledTextField from '../components/styledComponents/StyledTextField'
import { PagesStyle } from './Pages.style'

export default function LoginPage () {
  const style = PagesStyle()
  const [loginData, setLoginData] = useState({ username: '', password: '' })
  const [actionInProgress, setActionInProgres] = useState(false)
  const [message, setMessage] = useState('')
  const navigate = useNavigate()

  const onLoginButtonClick = () => {
    setActionInProgres(true)
    login(loginData).then((res) => {
      sessionStorage.setItem('token', JSON.stringify(res.data.access_token))
      getUserInfo().then((res) => {
        sessionStorage.setItem('user_data', JSON.stringify(res.data))
        navigate('/services')
        setActionInProgres(false)
      }).catch((err) => {
        setActionInProgres(false)
        setMessage(err.message)
      })
    }).catch((err) => {
      setActionInProgres(false)
      if (err?.response?.status === 400) { setMessage('Pogrešno korisničko ime i/ili lozinka') } else {
        setMessage(err.message)
      }
    })
  }

  const canSubmit = () => {
    return (loginData.username.length === 0) || (loginData.password.length === 0) || actionInProgress
  }

  return (
    <Stack
      style={ style.container }
      horizontalAlign='center'
      verticalAlign='center'>

      <Stack style={ style.formContainer }
        horizontalAlign='center'
        verticalAlign='center'
        tokens={{ childrenGap: 20 }}
      >
        <Stack
          style={ style.labelContainer }
          horizontal
          horizontalAlign='start'
          tokens={{ childrenGap: 10 }}>
          <span style={ style.labelLeft }>Fondacija</span>
          <span style={ style.labelRight }>LARA</span>
        </Stack>

        <StyledTextField
          label='Korisničko ime'
          name='username'
          required
          onChange={ (e: any, value: any) => {
            setLoginData({ ...loginData, [e.target.name]: value })
          } }
        />
        <StyledTextField
          canRevealPassword
          label='Lozinka'
          name='password'
          type='password'
          required
          onKeyDown={ (e: any) => {
            if (!canSubmit() && e.keyCode === 13) {
              onLoginButtonClick()
            }
          } }
          onChange={ (e: any, value: any) => {
            setLoginData({ ...loginData, [e.target.name]: value })
          } }
        />
        <StyledButton
          disabled={ canSubmit() }
          width='300px'
          onClick={ onLoginButtonClick }
        >
          { actionInProgress ? <Spinner /> : 'Prijavite se' }
        </StyledButton>
        { (message.length !== 0)
          ? <MessageBar
            styles={ style.messageBar }
            messageBarType={ MessageBarType.error }
            onDismiss={ () => { setMessage('') } }
          >
            { message }
          </MessageBar>
          : <div style={ style.messageBarPlaceholder } /> }
      </Stack>
    </Stack>
  )
}
