/* eslint-disable */
import { getAxiosInstance } from "./API";

interface QueryParams {
  [key: string]: string | number | boolean;
}

function buildQueryString(params: QueryParams) {
  const queryParams = [];
  for (const [key, value] of Object.entries(params)) {
    if (value !== null) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }
  }
  return queryParams.length > 0 ? "?" + queryParams.join("&") : "";
}
export const login = async (form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.post("/auth/login", { ...form });
};

export const getUserInfo = async () => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get("/auth/me");
};

export const searchPersons = async (searchTerm: string, searchBy: string) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/${searchBy}/search/?search_value=${searchTerm}`);
};

export const getAllUsers = async (page: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/users/all?page=${page}`);
};

export const createSafehouseEntry = async (form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.post("/safehouseentry/create", { ...form });
};

export const getAllSafehouseEntries = async (query: any) => {
  const API = await getAxiosInstance(); // Await the instance
  const queryString = buildQueryString(query);
  return await API.get(`/safehouseentry/all${queryString}`);
};

export const searchSafehouseEntry = async (id: any, victim?: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/safehouseentry/search/?safehouse_entry_id=${id}`);
};

export const deleteSafehouseEntry = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.delete(`/safehouseentry/${id}`);
};

export const editSafehouseEntry = async (id: any, form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.put(`/safehouseentry/${id}`, { ...form });
};

export const getSafehouseEntryByVictim = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/safehouseentry/search/?victim_id=${id}`);
};

export const getAllSoscallEntries = async (query: any) => {
  const API = await getAxiosInstance(); // Await the instance
  const queryString = buildQueryString(query);
  return await API.get(`/soscall/all${queryString}`);
};

export const createSoscallEntry = async (form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.post("/soscall/create", { ...form });
};

export const editSoscallEntry = async (id: any, form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.put(`/soscall/${id}`, { ...form });
};

export const getSoscallEntry = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/soscall/${id}`);
};

export const deleteSoscallEntry = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.delete(`/soscall/${id}`);
};

export const getSoscallEntriesByVictim = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/soscall/search/?victim_id=${id}`);
};

export const getAllAdvisoryHelpEntries = async (query: any) => {
  const API = await getAxiosInstance(); // Await the instance
  const queryString = buildQueryString(query);
  return await API.get(`/advisoryservice/all${queryString}`);
};

export const createAvisoryHelpEntry = async (form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.post("/advisoryservice/create", { ...form });
};

export const editAdvisoryHelpEntry = async (id: any, form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.put(`/advisoryservice/${id}`, { ...form });
};

export const getAdvisoryHelpEntry = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/advisoryservice/${id}`);
};

export const deleteAdvisoryHelpEntry = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.delete(`/advisoryservice/${id}`);
};

export const getAdvisoryServicesByVictim = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/advisoryservice/search/?victim_id=${id}`);
};

export const getAllTrustPersonEntries = async (query: any) => {
  const API = await getAxiosInstance(); // Await the instance
  const queryString = buildQueryString(query);
  return await API.get(`/trustedperson/all${queryString}`);
};

export const createTrustPersonEntry = async (form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.post("/trustedperson/create", { ...form });
};

export const editTrustPeronsEntry = async (id: any, form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.put(`/trustedperson/${id}`, { ...form });
};

export const getTrustedPersonEntry = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/trustedperson/${id}`);
};

export const deleteTrustedPersonEntry = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.delete(`/trustedperson/${id}`);
};

export const getTrustedPersonByVictim = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/trustedperson/search/?victim_id=${id}`);
};

export const createVictim = async (form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.post("/victim/create", { ...form });
};

export const editVictim = async (id: any, form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.put(`/victim/${id}`, { ...form });
};

export const getVictim = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/victim/${id}`);
};

export const deleteVictim = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.delete(`/victim/${id}`);
};

export const getAllVictims = async (query: any) => {
  const API = await getAxiosInstance(); // Await the instance
  const queryString = buildQueryString(query);
  return await API.get(`/victim/all${queryString}`);
};

export const getAllPerpetrators = async (query: any) => {
  const API = await getAxiosInstance(); // Await the instance
  const queryString = buildQueryString(query);
  return await API.get(`/perpetrator/all${queryString}`);
};

export const createPerpetrator = async (form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.post("/perpetrator/create", { ...form });
};

export const getPerpetrator = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/perpetrator/${id}`);
};

export const editPerpetrator = async (id: any, form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.put(`/perpetrator/${id}`, { ...form });
};

export const deletePerpetrator = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.delete(`/perpetrator/${id}`);
};

// User creation
export const createNewUser = async (form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.post("/auth/register", { ...form });
};

// Service codes APIs
export const createSafehouseDataType = async (form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.post("/safehousedatatype/create", { ...form });
};

export const getSafehouseDataTypes = async () => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get("/safehousedatatype/all?size=100");
};

export const getAdvisoryDataTypes = async () => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get("/advisoryservicehelptype/all?size=100");
};

export const createAdvisoryDataType = async (form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.post("/advisoryservicehelptype/create", { ...form });
};

export const deleteSafehouseDataType = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.delete(`/safehousedatatype/${id}`);
};

export const deleteAdvisoryDataType = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.delete(`/advisoryservicehelptype/${id}`);
};

export const createSafehouseEntryHelpEntry = async (form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.post("/safehousehelp/create", { ...form });
};

export const getSafehouseEntryHelpEntries = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/safehousehelp/${id}`);
};

export const deleteSafehouseHelpEntry = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.delete(`/safehousehelp/${id}`);
};

export const createProperties = async (form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.post("/properties/create", { ...form });
};

export const getProperties = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/properties/${id}`);
};

export const uploadFile = async (form: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.post("/uploadfile/", form);
};

export const downloadFile = async (id: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/getfilebyid/${id}`);
};

export const getFilesByVictim = async (id: any, page: any) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/getfilebyvictimid/${id}?page=${page}`);
};

export const getAdvisoryServicesReport = async (
  start_date: any,
  end_date: any,
  help_type: any
) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(
    `/report/advisoryservices?start_date=${start_date}&end_date=${end_date}&help_type=${help_type}`
  );
};

export const getSafehouseHelpReport = async (
  start_date: any,
  end_date: any,
  help_type: any
) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(
    `/report/safehousehelp?start_date=${start_date}&end_date=${end_date}&help_type=${help_type}`
  );
};

export const getSafehouseHelpVictimReport = async (
  start_date: any,
  end_date: any,
  help_type: any,
  victim_id: any
) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(
    `/report/safehousehelp/victim?start_date=${start_date}&end_date=${end_date}&help_type=${help_type}&victim_id=${victim_id}`
  );
};

export const getSafehouseHelpTotalDaysReport = async (
  start_date: any,
  end_date: any
) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(
    `/report/safehousetotaldays?start_date=${start_date}&end_date=${end_date}`
  );
};

export const getSafehouseHelpTotalDaysVictimReport = async (
  start_date: any,
  end_date: any,
  victim_id: any
) => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(
    `/report/safehousetotaldays?start_date=${start_date}&end_date=${end_date}&victim_id=${victim_id}`
  );
};

export const getSafetyMeasuresReport = async () => {
  const API = await getAxiosInstance(); // Await the instance
  return await API.get(`/report/safetymeasures`);
};
